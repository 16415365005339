<template>
  <QuestionnaireBase :title="title" :form="form" :finishFunction="null">
    <template v-slot:default>
      <component :is="show" :key="1" :ref="show" v-model="form"/>
      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />
    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "@/views/questionnaires/loadHelpers";
import {dateFormat} from "@/mixins/dateFormat";

import QuestionnaireBase from "../../../../components/common/questionnaires/questionnaire";
import DistributionPrimary from "@/components/common/questionnaires/fieldsets/will/DistributionPrimary";
import DistributionSecondary from "@/components/common/questionnaires/fieldsets/will/DistributionSecondary";
import DistributionBackup from "@/components/common/questionnaires/fieldsets/will/DistributionBackup";

export default {
  name: "WillDistribution",
  mixins: [loadHelpers, dateFormat],
  components: {
    QuestionnaireBase,
    DistributionPrimary,
    DistributionSecondary,
    DistributionBackup
  },
  data() {
    return {
      name: 'WillDistribution',
      title: "Will Distribution",
      url: 'will_full',
      order:
          [
            'DistributionPrimary',
            'DistributionSecondary',
            'DistributionBackup'
          ],
      expiryAccessCodeOnFinish: true
    }
  }
};
</script>
